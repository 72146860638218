function getSenderIframe(message: MessageEvent) {
  const iframes = Array.from(document.querySelectorAll("iframe"));
  return iframes.find((iframe) => iframe.contentWindow == message.source);
}

function openFullScreen(iframe: HTMLElement) {
  iframe.classList.add("pulzio-fullscreen-iframe");
  document.body.classList.add("pulzio-fullscreen-body");
}

function closeFullScreen(iframe: HTMLElement) {
  iframe.classList.remove("pulzio-fullscreen-iframe");
  document.body.classList.remove("pulzio-fullscreen-body");
}

const STYLE_ID = "pulzio-embed-styles";

function setupClasses() {
  const existingStyle = document.getElementById(STYLE_ID);
  if (existingStyle) {
    return;
  }

  const style = document.createElement("style");
  style.innerHTML = `
  .pulzio-fullscreen-body {
    overflow: hidden;
  }
  .pulzio-fullscreen-iframe {
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
  }
  `;
  style.id = STYLE_ID;
  document.getElementsByTagName("head")[0].appendChild(style);
}

setupClasses();

window.addEventListener(
  "message",
  (event: MessageEvent<{ type?: string } | undefined>) => {
    if (!event.data) {
      return;
    }

    if (!event.data.type) {
      return;
    }

    const type = event.data.type;

    if (!type.startsWith("pulzio:")) {
      return;
    }

    const iframe = getSenderIframe(event);

    if (!iframe) {
      console.warn("No iframe found for message", event);
      return;
    }

    if (type === "pulzio:openFullScreen") {
      openFullScreen(iframe);
    } else if (type === "pulzio:closeFullScreen") {
      closeFullScreen(iframe);
    }
  },
);
